html {
	box-sizing: border-box;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

*, *:before, *:after {
	box-sizing: inherit;
}

body {
	font-family: Roboto, sans-serif;
	margin: 0;
	padding: 0;
}

h1, h2, h3, h5, h6, p, ul {
	margin: 0;
	padding: 0;
}

.content {
	margin-top: 20px;
	padding: 24px;
}

.contentcenter {
	margin-top: 50px;
	padding: 10px;
	text-align: center;
}

.float-right {
	float: right;
}

.bodymap {
	display: flex;
}

.menu {
	position: fixed;
	width: 100%;
	margin-bottom: 5em;
	z-index: 1;
}

/*TODO: change this. */
.leftcolumn {
	margin: 3.6em 0em 3em 0em;
	float: left;
	height: calc(100vh - 6.6em);
	overflow-y: auto;
}

.leftActions {
	margin-left: auto;
	padding: 0.5em 0em 0.5em 2em;
	width: 25em;
	bottom:0;
	background-color: #fff;
	text-align: center;
}

.card {
	width: 18em;
}

.noPadding {
	padding: 0 !important;
}

.multiple-select-cc {
	width: 50px;
}

.farmdoc-card {
	cursor: pointer;
}

.farmdoc-card-active {
	background-color: rgba(84, 105, 255, 0.24);
	cursor: pointer;
}

.farmdoc-card-disabled {
	background-color: rgba(28, 29, 65, 0.24);
	cursor: pointer;
}

.line-chart-div{
	width: 450px;
	float: left;
	margin-bottom: 20px;
}

.line-chart-parent-div{
	position: relative;
}


.error-message {
	color: #d50000 !important;
	margin-left: 8px;
	font-family: Roboto, sans-serif;
	font-weight: 400;
	letter-spacing: 1px;
	margin:0px;
}

.bold-text {
	font-size: 16px;
	font-weight: bold;
	line-height: 24px;
}

.user-account-icon {
	margin-right: 20px;
	cursor: pointer;
	font-size: 32px;
}

.container {
	padding:0;
	margin:0;
}

.no-bottom-grid.mdc-layout-grid {
	padding-bottom: 0px;
}

.no-padding-grid.mdc-layout-grid {
	padding: 0px;
}

.border-left{
	border-left: 1px solid #D8D8D8;
}

.border-right{
	border-right: 1px solid #D8D8D8;
}

.border-top{
	border-top: 2px solid #D8D8D8;
}

.secondary-color {
	color: #455A64;
}

.warning-message{
	color: #fac312;
}

.go-up {
	margin-top: -35px;
}


/*not working*/
/*.ol-zoom {*/
/*left: unset;*/
/*right: 8px;*/
/*}*/

.position-relative {
	position: relative;
	height:auto;
}

.blue-button {
	height: 33px;

	color: #FFFFFF;
	background-color: #34515E;
	font-size: 18px;
	font-weight: 500;
	line-height: 21px;
	text-align: center;
}

.blue-button.small-text {
	font-size: 10px;
	font-weight: 500;
}

.blue-button:disabled {
	background-color: #c7cdd0;
}

.cancel-button {
	height: 28px;

	color: #1AB146;
	font-family: Roboto;
	font-size: 14px;
	line-height: 28px;
	text-align: center;
}

.dotted-hr {
	border-top: dotted 1px;
}


.loading_spinner {
	border-radius: 50%;
	width: 10em;
	height: 10em;
	border: 1em dashed #29ABE2;
	-webkit-animation: spinner 4s linear infinite;
	-moz-animation: spinner 4s linear infinite;
	animation: spinner 4s linear infinite;
	margin: 25vh auto;
	z-index: 1000;
}

.make_modal {
	background-color: rgba(0, 0, 0, 0.6);
	height: calc(100vh - 8em);
	width: 100%;
	z-index: 999;
	position: fixed;
	left: 0;
	top: 0;
	margin: 8.2em 0 0 0;
}

@-webkit-keyframes spinner {
	0% { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}

@-moz-keyframes spinner {
	from { -moz-transform: rotate(0deg); }
	to { -moz-transform: rotate(360deg); }
}

@keyframes spinner {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

.bin-header{
	color: white;
	font-size: 24px;
	font-weight: bold;
	margin-left: 1px;
	padding-top: 20px;
	padding-left: 25px;
	padding-right: 10px;
	padding-bottom: 10px;
}

.bin-subheader{
	color: white;
	font-size: 18px;
	font-weight: bold;
	margin-left: 1px;
	padding-top: 5px;
	padding-left: 25px;
	padding-right: 10px;
	padding-bottom: 15px;
}

.bin-griditem{
	padding-top: 10px;
	padding-bottom: 15px;
	padding-right: 20px;
	border-style: none;
}

.bin-checkbox{
	padding-top: 5px;
	padding-left: 30px;
	padding-right: 0px;
	padding-bottom: 1px;
	border-style: none;
	text-align: left;
}

.notification{
	font-size: 13px;
	background-color: #F9EDBE;
	color: black;
	text-align: center;
	border-radius: 3px;
	padding: 2px 10px 2px 10px;
}

.table-header-tooltip{
	text-decoration-line: underline;
	text-decoration-style: dotted;
	font-size: 13px !important;
	padding-top: 8px !important;
	padding-bottom: 8px !important;
}

.table-header-insurance{
	/*text-decoration-line: underline;*/
	/*text-decoration-style: dotted;*/
	font-weight: 900 !important;
	font-size: 14px !important;
}

.closeImg{
	cursor:pointer;
	float:right;
	margin-top: 5px;
	top: -24px;
	right: -24px;
	width: 24px !important;
	height: 24px !important;
}

.distributionCloseImg{
	cursor:pointer;
	float:right;
	margin-top: 5px;
	right: -24px;
	width: 24px !important;
	height: 24px !important;
}

.masterContent{
	min-height: calc(100vh - 116px);
	max-width: 1400px;
	margin: auto;
	border-left-color: darkgrey;
	border-left-style: double;
	border-right-color: darkgrey;
	border-right-style: double;
}

.termName{
	color: #34515E;
	font-size: 24px;
	font-weight: 500;
	padding-bottom: 8px;
	padding-left: 12px;
}

.termDefinition{
	color: #34515E;
	font-size: 14px;
	padding-bottom: 24px;
	padding-left: 12px;
	padding-right: 6px;
}

.docsHeader{
	color: #F66B16;
	font-size: 36px;
	font-weight: 500;
	line-height: 53px;
	padding-top: 8px;
	padding-bottom: 20px;
	padding-left: 8px;
}

.termLink{
	text-decoration: underline !important;
	color: #0000ff !important;
	padding-right: 6px;
}

.docMenu{
	max-width: 200px;
	background-color: #f8f8f8;
	height: 100%;
}


.appsHeader{
	text-align: center;
	padding-left: 20px;
	font-weight: 900;
	font-size: 18px;
}

.appHeader{
	padding: 6px;
	color: #FFFFFF;
	font-size: 18px;
	text-transform: uppercase;
	letter-spacing: 1.25px;
	line-height: 16px;
	border-radius: 4px;
	box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2);
	display: flex;
	align-items: center;
	text-align: center;
	margin: 0;
}

.appIcon{
	width: 52px;
	height: 40px;
	border-radius: 2px;
	padding:8px;
	margin-right: 8px;
	margin-left: auto;
}

.appName{
	margin-left: auto;
	margin-right: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}
.appLastUpdated{
	padding: 6px;
	color: #9B9B9B;
	font-size: 12px;

}

.appDescription{
	padding: 6px;
}

.appScreenshot {
	margin: 12px;
	text-align: center;
	box-sizing: border-box;
	max-height: 200px;
	max-width: 300px;
	border: 1px solid #979797;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
}

.appButton {
	background-color: #455A64 !important;
	font-size: large;
	/*margin-bottom: 10px;*/
	position: absolute !important;
	bottom:10px !important;
	right: 39%;
}

.iconSpan{
	vertical-align: bottom;
}
