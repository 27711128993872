.home-line {
	display: block;
	height: 3px;
	border: 3px solid #455A64;
	margin-right: 23px;
	margin-left: 23px;
}

.home-content {
	margin-right: 23px;
	margin-left: 23px;
	padding-top: 23px;
	width: auto;
	/*height: calc(100vh - 150px);*/
}

.mdc-card.login {
	height: auto;
	width: 280px;
	/*TODO: if set opacity, the text above seems blur*/
	/*opacity: 0.63;*/
	background-color: #CFEBD7;
	margin: 0 auto;
}

.login h2 {
	height: 54px;
	color: #34515E;
	font-size: 30px;
	font-weight: bold;
	line-height: 54px;
	text-align: center;
}

.login-error {
	background-color: #E05869;
	color: #FFF;
	padding: 5px 10px 5px 10px;
}

.login-error p{
	margin: -30px 0 0 30px;
	color: #FFF;
}

.login .mdc-text-field__bottom-line--active{
	background-color: #34515E;
}

.login .forget-password a{
	color: #455A64 !important;
	margin: 0 0 0 60px;
	width: 100%;
	text-align: center;
}

.login .mdc-button--raised:not(:disabled), .mdc-button--unelevated:not(:disabled){
	background-color: #34515E;
}


.loginInput{
	width: 200px;
}

.loginButton{
	width: 150px;
	margin-top: 10px;
	margin-bottom: 10px;
}


.login .mdc-card__supporting-text {
	color: #455A64;
	padding-bottom: 16px !important;
}

.login .not-active {
	pointer-events: none;
	cursor: default;
}

/*on the home page*/
.register-block {
	background-color: #108632;
	padding: 5px 10px 5px 10px;
	text-align: center;
}

.register-block p,a{
	color: #FFF ;
}

.register {
	height: 91px;
	width: 210px;
	text-align: center;
}

.unlogin .mdc-dialog__body{
	color: #0C0033 !important;
	text-align: center;
}

.unlogin .material-icons{
	font-size: 40px;
}

.unlogin .mdc-button:not(:disabled){
	color: #0C0033;
}


/*register page*/
.registration button{
	width: 160px;
}


.notification_div {
	position: relative;
	top: 6px;
	padding:4px 8px;
	text-align: center;
}

.isa_info, .isa_success, .isa_warning, .isa_error {
	margin: 0 0;
	padding:4px 6px;
	font-size: 15px;
	border-radius: 7px;
}

.isa_info {
	color: #00529B;
	background-color: #BDE5F8;
}
.isa_success {
	color: #4F8A10;
	background-color: #DFF2BF;
}
.isa_warning {
	max-width: 860px;
	display: inline-block;
	color: #9F6000;
	background-color: #FEEFB3;
}
.isa_error {
	color: #D8000C;
	background-color: #FFD2D2;
}
